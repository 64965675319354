import { useLazyQuery } from '@apollo/react-hooks';
import { PageHeader, DatePicker, Button, Space } from 'antd';
import { gql } from 'apollo-boost';
import React, { useCallback, useEffect, useState } from 'react';

const { RangePicker } = DatePicker;

const exportOrdersQuery = gql`
  query exportOrders($from: String!, $to: String!) {
    exportOrdersAsCsv(from: $from, to: $to)
  }
`;

const exportUsersQuery = gql`
  query exportUsers($from: String!, $to: String!) {
    exportUsersAsCsv(from: $from, to: $to)
  }
`;

const downloadInlineContent = (
  content: string,
  filename: string,
  contentType: string
): void => {
  const a = document.createElement('a');
  const blob = new Blob([content], { type: contentType });
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  a.parentNode?.removeChild(a);
};

export const ExportsPage: React.FC = () => {
  const [fromString, setFromString] = useState<string | undefined>(undefined);
  const [toString, setToString] = useState<string | undefined>(undefined);
  const [queryExportOrders, exportOrdersResult] = useLazyQuery(
    exportOrdersQuery
  );
  const [queryExportUsers, exportUsersResult] = useLazyQuery(exportUsersQuery);

  const downloadDisabled = !fromString || !toString;

  const onRangeChange = useCallback(([from, to]) => {
    setFromString(from.toISOString());
    setToString(to.toISOString());
  }, []);

  const onDownloadOrderExportClick = useCallback(() => {
    queryExportOrders({
      variables: {
        from: fromString,
        to: toString
      }
    });
  }, [fromString, toString, queryExportOrders]);

  const onDownloadUserExportClick = useCallback(() => {
    queryExportUsers({
      variables: {
        from: fromString,
        to: toString
      }
    });
  }, [fromString, toString, queryExportUsers]);

  useEffect(() => {
    const data = exportOrdersResult.data;
    if (!data) {
      return;
    }

    downloadInlineContent(
      data.exportOrdersAsCsv,
      'orders-export.csv',
      'text/csv'
    );
  }, [exportOrdersResult.data]);

  useEffect(() => {
    const data = exportUsersResult.data;
    if (!data) {
      return;
    }

    downloadInlineContent(
      data.exportUsersAsCsv,
      'users-export.csv',
      'text/csv'
    );
  }, [exportUsersResult.data]);

  return (
    <>
      <PageHeader title="Exports" subTitle="Download data exports"></PageHeader>
      <div className="header-padded">
        <Space direction={'vertical'}>
          <RangePicker onChange={onRangeChange} />
          <Button
            disabled={downloadDisabled}
            loading={exportOrdersResult.loading}
            onClick={onDownloadOrderExportClick}
          >
            Download order export
          </Button>
          <Button
            disabled={downloadDisabled}
            loading={exportUsersResult.loading}
            onClick={onDownloadUserExportClick}
          >
            Download users export
          </Button>
        </Space>
      </div>
    </>
  );
};
