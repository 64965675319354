import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider
} from '@react-firebase/auth';
import { useEffect } from 'react';
import { Button } from 'antd';

const firebaseConfig = {
  apiKey: 'AIzaSyCZfxr0i_itHAe91bOeqxkeCSFIUvbfqdE',
  authDomain: 'spoken-picture-f39e0.firebaseapp.com',
  projectId: 'spoken-picture-f39e0',
  appId: '1:939015934737:web:8b61e17c6532aef0e372b0',
  databaseURL:
    'https://spoken-picture-f39e0-default-rtdb.europe-west1.firebasedatabase.app/'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FirebaseTokenRenderer: React.FC<{ user: any }> = ({ user }) => {
  const [idToken, setIdToken] = useState('NONE');
  const [error, setError] = useState(undefined);
  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token: string) => setIdToken(token))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => setError(err));
  }, [user]);
  if (error) {
    return <div>{error}</div>;
  }
  return <div>ID token is: {idToken}</div>;
};

export const FirebaseAuthPage: React.FC = () => (
  <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
    <Button
      onClick={(): void => {
        firebase
          .auth()
          .signInAnonymously()
          .then((d) => console.log(d))
          .catch((e) => console.error(e));
      }}
    >
      Sign in anonymously
    </Button>

    <FirebaseAuthConsumer>
      {({ user }): JSX.Element => <FirebaseTokenRenderer user={user} />}
    </FirebaseAuthConsumer>
  </FirebaseAuthProvider>
);
